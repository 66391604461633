@tailwind base;
@tailwind components;
@tailwind utilities;


body {
	background-image: url('./bg.jpg');
	background-size: cover;
	background-position: top center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
