/* src/quill.css */

.ql-container {

    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .ql-toolbar.ql-snow {
    border-radius: 20px 20px 0 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .ql-snow .ql-picker-label {
    color: black;
  }
  
  .ql-snow .ql-stroke {
    stroke: black;
  }
  
  .ql-snow .ql-stroke-miter {
    fill: black;
  }
  
  .ql-snow .ql-picker {
    color: black;
  }
  
  .ql-snow .ql-picker-options {
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
  }